import Link from 'next/link';
import React from 'react';
import { classNames } from '#/packages/ui';
import { Anchor, AnchorProps } from '#/src/components/NavAnchor';
import { BetaLabel } from '#/src/components/Page/Logo/BetaLabel';
import { LogoImg } from '#/src/components/Page/Logo/LogoImg';

export const Logo = (props: {
  isDark?: boolean;
  width?: number;
  disableBy?: boolean;
  withLink?: boolean;
  withBeta?: boolean;
  linkProps?: Partial<AnchorProps>;
  className?: string;
  logoClassName?: string;
}) => {
  const {
    isDark,
    width,
    linkProps,
    disableBy,
    withLink = Boolean(linkProps),
    withBeta = true,
    className,
    logoClassName
  } = props;

  const logoElement = (
    <div className={classNames('text-center relative', className)}>
      <div className='flex justify-center items-center gap-2 h-12'>
        <LogoImg isDark={isDark} width={width || 48} className={logoClassName} />
        {withBeta ? <BetaLabel /> : null}
      </div>
      {!disableBy && (
        <Anchor
          size={'xs'}
          href={'https://superside.com'}
          target={'_blank'}
          className={
            'text-gray-500 dark:text-gray-500 tracking-wide absolute right-1/2 translate-x-1/2 translate-y-3 bottom-0'
          }
        >
          by Superside
        </Anchor>
      )}
    </div>
  );

  if (withLink) {
    return (
      <Link href={'/'} {...linkProps}>
        {logoElement}
      </Link>
    );
  }

  return logoElement;
};
